import { EscrowOrderActonsDto } from 'types/dtos/escrow-order-actions'
import { EscrowOrderActonsModel } from 'types/models/escrow-order-actions'

export const transformEscrowOrderActonsDto = (
  escrowOrderActons: EscrowOrderActonsDto,
): EscrowOrderActonsModel => ({
  shipmentId: escrowOrderActons.shipment_id,
  shippingOrderId: escrowOrderActons.shipping_order_id,
  shipmentStatus: escrowOrderActons.shipment_status,
  actions: escrowOrderActons.actions,
})
